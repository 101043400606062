import React, { Component } from 'react';
import qlogo from "../images/quibble.png"

class About extends Component {
  render() {

    if(this.props.data){
      var name = this.props.data.name;
      var profilepic= "images/"+this.props.data.image;
      var bio = this.props.data.bio;
      var description = this.props.data.description;
      var email = this.props.data.email;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic}
              alt="Tim Baker Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>Bio</h2>

            <p>{description}</p>
            <p>{bio}</p>
            <div className="row" id="contactInfo">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name}</span>
                  <br />
                  <span>{email}</span>
                </p>
              </div>
              <div className="columns download">
                <p>
                  <a href="https://quibblerm.com">
                    {" "}
                    <img
                      //  className="profile-pic"
                      src={qlogo}
                      width="200px"
                      alt="Quibble - Revenue Management for Vacation Rentals"
                    />
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
